<div class="input-wrapper">
  <label class="label align-items-center" [class.error]="error">
    <div>
      <span>{{ label }}</span>
      @if (required) {
      <span class="required">*</span>
      }
    </div>
    @if(trialing){
      <div>
        <span class="trialing">{{ trialing }}</span>
      </div>
    }
  </label>
  <ng-content></ng-content>
</div>
