import { Component, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-input-field',
  standalone: true,
  imports: [MatFormFieldModule],
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss',
})
export class InputFieldComponent {
  @Input() label: string;
  @Input() trialing: string;
  @Input() required?: boolean;
  @Input() error?: boolean;
  @Input() fullWidth?: boolean;
}
